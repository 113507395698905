import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faFrownOpen, faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import AppConfig from '../appconfig';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import InfoModal from "./infoModal";
import ProgressBar from 'react-bootstrap/progressBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  setDmSounds
} from "../state/actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import UserMedia from "./userMedia";
import Tag from "./tag";
import FloatingLabel from 'react-bootstrap/FloatingLabel';



class CreatePostContentInner extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			errorText: '',
			pageLoading: false,
			usernameInvalid: "",
			createPostFeedback: "", 
			signInFeedback: "",
			isLoading: false,
			topicSelected: '12',
			selectedTags: [],
			availableTags: ['s', 'm', 'sd', 'c', 'l', 'v', 'kd', 'mp'],
			textSelected: true,
			postSuccess: false,
			uploadedImage: "",
			formData: '',
			postId: '',
			showInfoModal: false,
			infoModalText: ''
		};

		this.handleImgClick = this.handleImgClick.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
		this.closeInfoModal = this.closeInfoModal.bind(this);
		this.uploadPostMedia = this.uploadPostMedia.bind(this);
		this.prepareUploadMedia = this.prepareUploadMedia.bind(this);
		this.setSelectedDate = this.setSelectedDate.bind(this);
		this.handleImgClick = this.handleImgClick.bind(this);
		this.handleCreatePost = this.handleCreatePost.bind(this);
		this.setIsLoading = this.setIsLoading.bind(this);
		this.setUsernameInvalid = this.setUsernameInvalid.bind(this);
		this.setCreatePostFeedback = this.setCreatePostFeedback.bind(this);
		this.createPost = this.createPost.bind(this);
		this.handleTopicChange = this.handleTopicChange.bind(this);
		this.tagSelected = this.tagSelected.bind(this);
		this.tagUnselected = this.tagUnselected.bind(this);
		this.setTextSelected = this.setTextSelected.bind(this);
	}

	handleCreatePost(event) {
		if (this.state.isLoading) {
			return;
		}

		const form = event.currentTarget;

		this.setIsLoading(true);
		this.createPost(form.elements.title.value, form.elements.text, form.elements.link);		
		
		event.preventDefault();
		event.stopPropagation();
	}

	createPost(title, text, link) {
		if (this.state.postId != undefined && this.state.postId != '') {
			// A postId was saved, meaning a post has already been created. 
			// An image upload may have failed earlier, go straight to upload image and try again.
			this.uploadPostMedia(this.state.postId);
			return false;
		}

		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'createPost';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				title: title,
				text: text != undefined ? text.value : '',
				link: link != undefined ? link.value : '',
				textSelected: this.state.textSelected,
				selectedTags: this.state.selectedTags,
				topic: this.state.topicSelected
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					if (this.state.formData != undefined && this.state.formData != '') {
						// An image should be uploaded as well
						this.uploadPostMedia(result.id);
					} else {
						this.setState({
							createPostFeedback: '',
							isLoading: false,
							postSuccess: true
						});
					}				
				} else {
					let feedback = 'Kunde inte skapa. Försök igen senare.'

					if (result.errorCode == 3) {
						// Set the state to show silenced
						this.setState({
							showInfoModal: true,
							infoModalText: "Du är tystad och kan inte prata just nu"
						}); 
					} else if (result.errorCode == 4) {
						feedback = this.props.intl.formatMessage({ id: 'titleMustBe' });
					} else if (result.errorCode == 5) {
						feedback = this.props.intl.formatMessage({ id: 'descriptionMustBe' });
					} else if (result.errorCode == 101) { 
						window.location.reload();
					}
					
					this.setState({
						createPostFeedback: feedback,
						isLoading: false
					});
				}
			},
			error => {
				this.setState({
					createPostFeedback: 'Kunde inte skapa. Försök igen senare.',
					isLoading: false
				});
			}
		);

		return false;
	}


	setIsLoading(value) {
		this.setState({
			isLoading: value
		});
	}

	setUsernameInvalid(value) {
		this.setState({
			usernameInvalid: value
		});
	}

	setCreatePostFeedback(value) {
		this.setState({
			createPostFeedback: value
		});
	}

	setSignInFeedback(value) {
		this.setState({
			signInFeedback: value
		});
	}

	handleTopicChange(event) {
		this.setState({topicSelected: event.target.value});
	}


	handleImgClick(e, data) {
		this.setState({
			imageLightBoxSrc: data,
			showImageLightBox: true
		});
	}


	handleNameChange(event) {
		this.setState({
			newName: event.target.value
		});
	}

	handleDescriptionChange(event) {
		this.setState({
			newDescription: event.target.value
		});
	}

	closeInfoModal(e) {
		this.setState({
			showInfoModal: false
		});
	}

	setSelectedDate(selectedDate) {
		this.setState({
			selectedDate: selectedDate
		});
	}

	prepareUploadMedia(e) {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		const files = Array.from(e.target.files);

		var file = files[0];
		if (file == undefined) {
			return false;
		}

		var reader = new FileReader();
		reader.onloadend = function() {
			this.setState({
				uploadedImage: reader.result
			});
		}.bind(this);
		reader.readAsDataURL(file);

		const formData = new FormData();

		files.forEach((file, i) => {
			formData.append("file", file)
		})

		formData.append("userId", userId);
		formData.append("sessionId", sessionId);

		this.setState({
			uploadingMedia: true,
			formData: formData
		});
	}

	uploadPostMedia(postId) {
		let url = AppConfig.API_URL + 'uploadPostMedia';

		let formData = this.state.formData;
		formData.append("postId", postId);

		fetch(url, {
			method: 'post',
			credentials: 'include',
			body: formData
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					this.setState({
						createPostFeedback: '',
						isLoading: false,
						postSuccess: true
					});
				} else {
					let feedback = this.props.intl.formatMessage({ id: 'errorTryLater' });
					if (result.errorCode == 1) {
						feedback = this.props.intl.formatMessage({ id: 'fileNotAllowed' });
					} else if (result.errorCode == 2) {
						feedback = this.props.intl.formatMessage({ id: 'fileTooBig' });
					} else if (result.errorCode == 101) { 
						window.location.reload();
					}

					this.setState({
						createPostFeedback: feedback,
						uploadingMedia: false,
						postId: postId
					});
				}
			},
			error => {
				this.setState({
					uploadingMedia: false,
					createPostFeedback: this.props.intl.formatMessage({ id: 'errorTryLater' })
				});
			}
		);
	}

	handleImgClick(e, data) {
		this.setState({
			imageLightBoxSrc: data,
			showImageLightBox: true
		});
	}

	tagSelected(name, selected) {
		if (selected) {
			this.tagUnselected(name);
		} else {
			let newAvailableTags = this.state.availableTags.filter(tag => tag != name);
			let newSelectedTags = this.state.selectedTags;
			newSelectedTags.push(name);
			this.setState({
				availableTags: newAvailableTags,
				selectedTags: newSelectedTags
			});
		}
	}

	tagUnselected(name) {
		let newSelectedTags = this.state.selectedTags.filter(tag => tag != name);
		let newAvailableTags = this.state.availableTags;
		newAvailableTags.push(name);
		this.setState({
			availableTags: newAvailableTags,
			selectedTags: newSelectedTags
		});
	}

	setTextSelected(value) {
		this.setState({
			textSelected: value
		});
	}

	render() {
		let progressBarMedia = null;
		if (this.state.uploadingMedia) {
			progressBarMedia = <ProgressBar now={this.state.uploadMediaProgress} />
		}

		let media = [];
		let mediaUrls = this.state.mediaUrls;
		if (mediaUrls != undefined && mediaUrls.length > 0) {
			for (let i = 0; i < mediaUrls.length; i++) {
				media.push (
					<UserMedia fileUrl={mediaUrls[i].fileUrl} fileType={mediaUrls[i].type} ownMedia={true}
						handleImgClick={this.handleImgClick} removeMedia={this.removeMedia} />
				);
			}
		}

		let availableTags = [];
		let availableTagsState = this.state.availableTags;
		if (availableTagsState != undefined && availableTagsState.length > 0) {
			for (let i = 0; i < availableTagsState.length; i++) {
				availableTags.push (
					<Tag key={availableTagsState[i]} name={availableTagsState[i]} tagSelected={this.tagSelected} />
				);
			}
		}

		let selectedTags = [];
		let selectedTagsState = this.state.selectedTags;
		if (selectedTagsState != undefined && selectedTagsState.length > 0) {
			for (let i = 0; i < selectedTagsState.length; i++) {
				selectedTags.push (
					<Tag key={availableTagsState[i]} name={selectedTagsState[i]} tagSelected={this.tagSelected} selected={true} />
				);
			}
		}

		return (
			<div className="pageWrapper page textPage">
				<div className="h-100 chatWrapper">

					<div className="row createPostContent">

						<div className="">
								
							<div className={this.state.pageLoading ? 'dcLoader medium preLoader' : 'hidden'} />

			
								{this.state.postSuccess && 
									<div className="errorText">Din post är skapad och kommer att synas snart! <br /> <FontAwesomeIcon icon={faThumbsUp} /></div>
								}

								<div className={this.state.pageLoading || this.state.postSuccess ? 'hidden' : 'card-body msgCardBody'}>
									
									<div className="">

										<InfoModal show={this.state.showInfoModal} closeInfoModal={this.closeInfoModal} body={this.state.infoModalText} />
										
										{this.state.errorText == '' && !this.state.pageLoading ? 
												

								<div className="main-content">

								<div className="postButtons">
									<Button className={this.state.textSelected ? '' : 'unselectedPost'} onClick={() => this.setTextSelected(true)}>
										Text
									</Button>

									<Button className={this.state.textSelected ? 'unselectedPost' : ''} onClick={() => this.setTextSelected(false)}>
										Länk
									</Button>
								</div>

								<Form onSubmit={this.handleCreatePost} className="createPostForm">

									{this.state.textSelected ? 
										<div>
											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Rubrik"
													className="mb-3"
													>
													<Form.Control required type="text" minLength="5" maxLength="80" name="title" placeholder="" />
													<Form.Control.Feedback type="invalid">
														<FormattedMessage id="titleMustBe" />
													</Form.Control.Feedback>
												</FloatingLabel>
											</Form.Group>

											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Text"
													className="mb-3"
													>

													<Form.Control required minLength="10" maxLength="800" as="textarea" name="text" placeholder=""
														onChange={this.handleDescriptionChange} rows="6" />
													<Form.Control.Feedback type="invalid">
														<FormattedMessage id="descriptionMustBe" />
													</Form.Control.Feedback>

												</FloatingLabel>
											</Form.Group>
										</div>
									:
										<div>
											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Rubrik (valfritt)"
													className="mb-3"
													>
													<Form.Control type="text" minLength="5" maxLength="80" name="title" />
													<Form.Control.Feedback type="invalid">
														<FormattedMessage id="titleMustBe" />
													</Form.Control.Feedback>
												</FloatingLabel>
											</Form.Group>

											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Länk"
													className="mb-3"
													>

													<Form.Control required type="text" minLength="5" maxLength="1500" name="link"/>
													<Form.Control.Feedback type="invalid">
														<FormattedMessage id="titleMustBe" />
													</Form.Control.Feedback>
												</FloatingLabel>
											</Form.Group>
										</div>
									}

									<Form.Group>
										<fieldset>
											<FloatingLabel controlId="floatingSelect" label="Välj ett ämne" className="mb-3">

											<Form.Select value={this.state.topicSelected} onChange={this.handleTopicChange}>
												<option value="8">Ekonomi</option>
												<option value="6">Klimat</option>
												<option value="1">Sjukvård</option>
												<option value="3">Migration</option>
												<option value="2">Lag & ordning</option>
												<option value="4">Utbildning</option>
												<option value="5">Äldreomsorg</option>
												<option value="7">Jobb</option>
												<option value="9">Jämlikhet</option>
												<option value="10">Sport</option>
												<option value="11">Nöje</option>
												<option value="12">Övrigt</option>
											</Form.Select>

											</FloatingLabel>
										</fieldset>
									</Form.Group>

									<div className="searchBar">
										{this.state.selectedTags.length > 0 ? 
											selectedTags
											:
											<span className="tagsLabel">Taggade partier</span>
										}
									</div>

									<div className="availableTags">
										{availableTags}
									</div>

									{this.state.textSelected &&
										<div>	
											<div className="btn btn-sm btn-primary uploadAvatarBtn">
												<label htmlFor='mediaUpload'>
													<div className=''>
														<FontAwesomeIcon icon={faImage} /> Lägg till bild
														<input type='file' id='mediaUpload' onChange={this.prepareUploadMedia} />
													</div>
												</label>
											</div>

											{this.state.uploadedImage != undefined && this.state.uploadedImage != '' && 
												<div className="uploadPostImage">
													<img src={this.state.uploadedImage} />
												</div>
											}
										</div>
									}

									<p className="feedback">{this.state.createPostFeedback}</p>
									<Button className="purpleBtn" type="submit" disabled={this.state.isLoading}>{
										this.state.isLoading ? 'Skapar' : 'Skapa'
										}
									</Button>
								</Form>


								</div>


										 : null
										}

										{this.state.errorText != '' &&  !this.state.pageLoading ? 
											<div className="errorText">{this.state.errorText} <br /> <FontAwesomeIcon icon={faFrownOpen} /></div>
										 : null
										}

									</div>
									
								</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, messages } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setDmSounds
	},
	dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(CreatePostContentInner));
