import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import UserMenu from "./userMenu";
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faBan, faEnvelope, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl';

//import YoutubePlayer from 'react-player/youtube'
//import FilePlayer from 'react-player/file'
//import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/lazy'
import Button from 'react-bootstrap/Button';


function UserMedia(props) {

	const [loadingError, setLoadingError] = React.useState(false);
	const target = useRef(null);
	const [show, setShow] = useState(false);

	const handleError = () => {
		setLoadingError(true);
		props.imageLoaded();
	};


	let msgBody = null;
	
	if (props.fileType == 4) {
		// Is a video
		return (
			<div className="userMedia video">
				{props.ownMedia &&
					<div className="removeCross removeMedia" onClick={() => props.removeMedia(props.fileUrl)}>
						<FontAwesomeIcon icon={faTimes} />
					</div>
				}
				<ReactPlayer className='react-player' height="230px" width="100%" controls={true} url={props.fileUrl} />
			</div>
		);
	} else if (props.fileType == 2) {
		return null;
	} else {
		// Is an image
		if (loadingError) {
			msgBody = (
				<div className="imageNotLoadedText">Image could not be loaded</div>
			);
		}
		
		return (
			<div className="userMedia">
				{props.ownMedia &&
					<div className="removeCross removeMedia" onClick={() => props.removeMedia(props.fileUrl)}>
						<FontAwesomeIcon icon={faTimes} />
					</div>
				}
				<img src={props.fileUrl} onClick={((e) => props.handleImgClick(e, props.fileUrl))}  onError={() => handleError()} alt="" />
			</div>
		);
	}


	
}



export default UserMedia;